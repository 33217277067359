@media screen and (min-height: 810px) {
    #connexion {
        height: 100vh !important;
    }
}

#connexion {
    background: #00000050 url(http://eng.btpvirt.com/scott-graham-5fNmWej4tAA-unsplash.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: darken;

    height: fit-content;
}

#connexion > #content {
    padding-top: 250px;
    padding-bottom: 20px;
}

#connexion > #content > #main, #connexion > #content > #inscription {
    width: 700px;

    padding-top: 20px;
    padding-bottom: 30px;

    background: #00000030;
    
    border: 2px solid #FF7A1A;
    border-radius: 30px;
    
    color: white;
    
    text-align: center;
    
    position: relative;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
}

#connexion > #content > #main > #identifiants > input {
    width: 500px;
    height: 50px;

    font-size: 1.1em;
    
    padding-left: 15px;
    margin-bottom: 20px;
    
    border: none;
    border-radius: 10px;

    box-shadow: 0px 2px 0px 0px #FF7A1A;
}

#connexion > #content > #main > #identifiants > input:focus {
    outline: none;
    box-shadow: 0px 2px 0px 0px #00000090;
}

#connexion > #content > #main > #identifiants > input:invalid {
    box-shadow: 0px 2px 0px 0px #BB0A1E80;
}

#connexion > #content > #main > #checkbox {
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 5px;
}

#connexion > #content > #main > button, #connexion > #content > #inscription > button {
    width: 225px;
    height: 50px;

    font-size: 1.3em;
    
    border: none;
    border-radius: 10px;
    
    background: #FF7A1A99;
    
    color: white;

    cursor: pointer;
}

#connexion > #content > #inscription {
    display: flex;
    align-items: baseline;
    gap: 150px;

    margin-top: 20px;
    margin-bottom: 20px;
}

#connexion > #content > #inscription > h3 {
    margin-left: 30px;
}