.inscription {
    width: 600px;

    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;

    background: #FF7A1A05;

    border: 1px solid #FF7A1A;
    border-radius: 15px;
    
    position: relative;
    top: 250px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
}

.inscription > p {
    margin-top: 25px;
    margin-left: 30px;
    
    opacity: 80%;
}

.inscription > #type {
    display: flex;
    align-items: baseline;
    gap: 20px;

    margin-top: 60px;
    margin-bottom: 10px;
}

.inscription > #type > span {
    font-size: 1.4em;
}

.inscription > #type > button {
    width: 180px;
    height: 40px;

    font-size: 1.2em;
    
    background: #FF7A1A99;
    
    border: none;
    border-radius: 10px;

    cursor: pointer;
}