/* Mail */

.inscription > input {
    width: 500px;
    height: 50px;

    font-size: 1.1em;
    
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    
    border: none;
    box-shadow: 0px 2px 0px 0px #FF7A1A;
}

.inscription > input:focus {
    outline: none;
    box-shadow: 0px 2px 0px 0px #00000090;
}

.inscription > input:invalid {
    box-shadow: 0px 2px 0px 0px #BB0A1E80;
}

.inscription > button {
    width: 225px;
    height: 50px;

    font-size: 1.3em;

    margin-top: 20px;
    
    border: none;
    border-radius: 5px;
    
    background: #FF7A1A99;

    cursor: pointer;
}

.inscription > button, .inscription > input {
    position: relative; 
    left: 50%;
    right: 50%;
    transform: translateX(-50%);

    border-radius: 10px;
}


/* Vérification mail */

#verification_mail > p {
    margin-bottom: 0;
}

#verification_mail > span {
    margin-left: 50px;
}

.inscription > #code {
    display: flex;
    gap: 50px;
    justify-content: center;

    margin-top: 40px;
}

.inscription > #code > div {
    display: flex;
    gap: 20px;
}

.inscription > #code > div > div > input {
    width: 50px;
    height: 70px;

    border-radius: 5px;
    border: 1px solid #FF7A1A50;

    text-align: center;
    font-size: 2em;
}

.inscription > #renvoyer_code {
    display: block;
    text-align: center;
    margin-top: 40px;
}

.inscription > #renvoyer_code > span {
    color: #FF7A1A80;
    text-decoration: underline;

    cursor: pointer;
}


/* Informations */

.inscription > #infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.inscription > #infos > input {
    width: 250px;
    height: 50px;

    font-size: 1.1em;
    
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 25px;
    
    border: none;
    border-bottom: 1px solid #FF7A1A50;
    border-radius: 10px;
}