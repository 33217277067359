.search_page {
    /* width: 1000px; */

    text-align: center;
    
    position: relative;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
}


.search_page > #top {
    height: fit-content;

    padding-bottom: 50px;

    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    background-blend-mode: darken !important;
}

div.search_page#materiel > #top {
    background: #00000050 url(http://eng.btpvirt.com/etienne-girardet-sgYamIzhAhg-unsplash.jpg);
}

div.search_page#artisant > #top {
    background: #00000050 url(http://eng.btpvirt.com/henry-co-3coKbdfnAFg-unsplash.jpg);
}

div.search_page#emploi > #top {
    background: #00000050 url(http://eng.btpvirt.com/guilherme-cunha-0ZOtNzDVUZg-unsplash.jpg)
}

.search_page > #top > h1 {
    margin-top: 0;
    margin-bottom: 50px;

    padding-top: 250px;

    font-size: 2.5em;
    
    color: white;
}

.search_page > #top > #add_button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    cursor: pointer;

    background-color: #ff7A1A99;
    
    width: fit-content;

    margin-bottom: 60px;
    padding: 10px;
    
    position: relative;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);

    font-size: 1.1em;

    color: white;

    border-radius: 15px;
    border: 1px solid white;
}

.search_page > #top > #add_button > img {
    width: 25px;
    height: 25px;
}

@media screen and (max-width: 800px) {
    .search_page > #top > #search {
        width: 90vw !important;
    }

    .search_page > #top > #search > #input > input {
        width: 70vw !important;
        height: 40px !important;
        border-radius: 4px !important;
    }

    .search_page > #top > #search > #input > #categories  {
        display: none !important;
    }
}

.search_page > #top > #search {
    width: 800px;

    margin-top: 20px;
    margin-bottom: 40px;

    padding-top: 30px;

    border: 2px solid #FF7A1A;
    border-radius: 30px;

    position: relative;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);

    background: #00000030;
}

.search_page > #top > #search > #types {
    font-size: 1.2em;
    text-align: start;
    color: white;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.search_page > #top > #search > #types > span > label {
    margin-left: 5px;
}

.search_page > #top > #search > #input {
    display: flex;
    justify-content: center;

    margin-top: 20px;
}

.search_page > #top > #search > #input > input {
    border: none;
    border-bottom: 2px solid #FF7A1A;
    border-radius: 10px 0 0 10px;

    width: 470px;
    
    font-size: 1.3em;
    
    padding-left: 10px;
}

.search_page > #top > #search > #input > #categories {
    display: flex;
    align-items: center;
    gap: 15px;

    background: #FF7A1A50;

    border: 1px solid #FF7A1A;
    border-radius: 0 10px 10px 0;
    
    padding-left: 15px;
    padding-right: 20px;
    
    font-size: 1.3em;

    color: white;
}

.search_page > #top > #search > #input > #categories > img {
    width: 47px;
    height: 47px;
}

.search_page > #top > #search > #options {
    margin-top: 20px;
    
    display: flex;
    justify-content: center;
    gap: 50px;
}

.search_page > #top > #search > #options > button {
    height: 40px;

    padding-right: 10px;
    
    background: #FF7A1A50;
    
    border: 1px solid #FF7A1A;
    border-radius: 5px;

    font-size: 1.1em;

    color: white;

    display: flex;
    align-items: center;
    gap: 10px;
}

.search_page > #top > #search > #options > button > img {
    height: 24px;
}

.search_page > #top > #search > #search_button > button {
    position: relative;
    top: 25px;

    width: 160px;
    height: 50px;
    
    font-size: 1.3em;
    
    background: white;

    border: 2px solid #FF7A1A50;
    border-radius: 5px;
}

.search_page > #publicite {
    width: 900px;
    height: 130px;

    margin-top: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    position: relative;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);

    background: #00000010;
}

.search_page > #publicite > img {
    height: 100px;

    opacity: 80%;
}

.search_page > #publicite > span {
    font-size: 1.3em;
    
    opacity: 80%;
}

.search_page > #all_resuts {
    width: 900px;

    position: relative;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
}

.search_page > #all_resuts > h2 {
    text-align: start;
    font-size: 1.7em;
}

@media screen and (max-width: 800px) {
    .search_page > #publicite {
        width: 100vw;
    }    
    .search_page > #all_resuts {
        width: 100vw !important;
    }

    .search_page > #all_resuts > h2 {
        text-align: center;
    }
    
    .search_page > #all_resuts > #annonces {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .search_page > #all_resuts > #annonces > .annonce {
        width: 90vw;
        flex-direction: column;
        position: relative;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .search_page > #all_resuts > #annonces > .annonce > .image {
        border-radius: 10px !important;
    }

    .search_page > #all_resuts > #annonces > .annonce > .text_annonce {
        width: 80vw !important;
    }
}

.search_page > #all_resuts > #annonces > .annonce {
    background: #FF7A1A10;

    border-radius: 25px;

    display: flex;
    gap: 30px;
    margin-bottom: 10px;

    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
}

.search_page > #all_resuts > #annonces > .annonce:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 0 10px 0 #FF7A1A
}

.search_page > #all_resuts > #annonces > .annonce > .image {
    width: 330px;

    background: #e7e7e7;

    border-radius: 25px 0 0 25px;
}

.search_page > #all_resuts > #annonces > .annonce > .image > img {
    width: 160px;
    height: 160px;

    padding-top: 20px;
    padding-bottom: 20px;

    opacity: 50%;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce {
    width: 520px;

    text-align: start;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .head > div > p {
    display: inline-block;

    font-size: 1.4em;

    margin: 0;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .head > div > .pro {
    margin-left: 20px;
    
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    
    border: 1px solid;
    border-radius: 5px;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .head > .type {
    background: #FF7A1A30;

    padding: 5px;

    border-radius: 11px;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .description {
    color: #00000090;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .foot > .rating {
    display: flex;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .foot > .rating > span > .rating_star {
    width: 20px;
    height: 20px;
}

.search_page > #all_resuts > #annonces > .annonce > .text_annonce > .foot > .rating > .rating_nb {
    margin-left: 10px;
}

.search_page > #all_resuts > #annonces > #pages {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    font-size: 1.4em;
    
    margin-top: 40px;
}

.search_page > #all_resuts > #annonces > #pages > .page_nav {
    width: 30px;
    height: 30px;
}